import React from 'react';
import TextUserInput from './TextUserInput';
import ComboUserInput from './ComboUserInput';
import DateUserInput from './DateUserInput';
import CheckUserInput from './CheckUserInput';
import TypeaheadUserInput from './TypeaheadUserInput';
import EmailUserInput from './EmailUserInput';
import MapUserInput from './MapUserInput';
import { Grid } from '@material-ui/core';
import LinkUserInput from './LinkUserInput';

const UserInput = (props) => {
    const { userInput, hashKey, stepName, onChange } = props;
    const options = userInput.userInputOptions;

    const handleValueChange = (userInput, value) => {
        onChange(userInput, value);
    };

    return (
        <>
            {userInput && options && (
                <>
                    {options.type === 'TEXT' && (
                        <Grid container className="mt-1">
                            <TextUserInput userInput={userInput} onChange={handleValueChange} />
                        </Grid>
                    )}

                    {options.type === 'EMAIL' && (
                        <Grid container className="mt-1">
                            <EmailUserInput userInput={userInput} onChange={handleValueChange} />
                        </Grid>
                    )}

                    {options.type === 'TEXT_AUTOCOMPLETE' && (
                        <Grid container className="mt-1">
                            <TextUserInput userInput={userInput} onChange={handleValueChange} />
                        </Grid>
                    )}

                    {options.type === 'COMBO' && (
                        <Grid container className="mt-1">
                            <ComboUserInput
                                hashKey={hashKey}
                                stepName={stepName}
                                userInput={userInput}
                                onChange={handleValueChange}
                                conditioningValue={props.conditioningValue}
                            />
                        </Grid>
                    )}
                    {options.type === 'LINK' && (
                        <Grid container className="mt-4"  alignItems='center'>
                            <LinkUserInput
                                hashKey={hashKey}
                                stepName={stepName}
                                userInput={userInput}
                                conditioningValue={props.conditioningValue}
                            />
                        </Grid>
                    )}

                    {options.type === 'TYPEAHEAD' && (
                        <Grid container className="mt-1">
                            <TypeaheadUserInput
                                hashKey={hashKey}
                                stepName={stepName}
                                userInput={userInput}
                                onChange={handleValueChange}
                                conditioningValue={props.conditioningValue}
                                onAnalyticsEvent={props.onAnalyticsEvent}
                            />
                        </Grid>
                    )}

                    {options.type === 'DATE' && (
                        <Grid item container xs={12} className="mt-1">
                            <DateUserInput userInput={userInput} onChange={handleValueChange} />
                        </Grid>
                    )}
                    {options.type === 'CHECK' && (
                        <Grid container className="mt-1">
                            <CheckUserInput userInput={userInput} onChange={handleValueChange} />
                        </Grid>
                    )}
                    {options.type === 'MAP' && (
                        <Grid container className="mt-1">
                            <MapUserInput userInput={userInput} onChange={handleValueChange} stepName={stepName} conditioningValue={props.conditioningValue}/>
                        </Grid>
                    )}
                </>
            )}
        </>
    );
};

export default UserInput;

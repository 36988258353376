import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, useTheme, useMediaQuery, CircularProgress, Box } from '@material-ui/core';
import disclosureImage from '../../assets/signdisclosureimg.svg';
import exclamationImage from '../../assets/exclamation.svg';
import documentService from '../../services/document.service';
import eventTrackingService from '../../services/eventtracking.service';
import '../../styles/components/disclosure.scss';
import AcceptContractCheckbox from '../login/AcceptContractCheckbox';

const Disclosure = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSmSize = useMediaQuery(theme.breakpoints.down('sm'));
    const { loanRequest } = props;

    const [showDisclosureLoader, setShowDisclosureLoader] = useState(false);

    const handleDisclosureDownloadClicked = (event) => {
        event.preventDefault();
        
        if(!showDisclosureLoader) {
            setShowDisclosureLoader(true);
            documentService.openDisclosureDocumentInNewTab(loanRequest.hashKey)
                .then(function (response) {
                    var url = window.URL.createObjectURL(response.data);
                    setShowDisclosureLoader(false);
                    window.open(url);
                    eventTrackingService.trackEvent('prestamos_descargar_detalle', null, null);
                })
                .catch((err) => {
                    console.error(err);
                    setShowDisclosureLoader(false);
                });
        }
        
    };

    const handleAcceptTermsChange = (event) => {
        if (event === true) {
            props.onSign();
        } else {
            props.onUnsign();
        }
    };

    return (
        <Grid container className={'mt-2'}>
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <div className="box">
                        <img src={disclosureImage} width="40px" />
                        <p>
                            Antes de firmar el contrato podés{' '}
                            <a href="#" onClick={handleDisclosureDownloadClicked}>
                                {' '}
                                leerlo y bajarlo acá
                            </a>{' '}
                            
                            <p className={'fs-12 fw-400 fst-reg'} hidden={!showDisclosureLoader}>
                                Descargando...{' '}
                                <CircularProgress className={'mt-1 ml-1'} size={15}/>
                            </p>
                        </p>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <AcceptContractCheckbox onChange={handleAcceptTermsChange} onContractLinkClicked={handleDisclosureDownloadClicked} />
                </Grid>
                <Box m={2} />
                <div className="box">
                    <img src={exclamationImage} width="35px" />
                    <p>
                        Las cuotas se debitarán automáticamente de tu cuenta.
                    </p>
                </div>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(() => ({
    titleText: {
        fontFamily: 'Roboto',
        fontSize: '24px',
        fontWeight: '500',
        lineHeight: '40px',
        color: '#082f57',
    },
    titleTextMobile: {
        fontFamily: 'Roboto',
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '26px',
        color: '#082f57',
    },
}));

export default Disclosure;

import { Box, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import logoexl from '../../assets/exclamacion.svg';
import arrowRight from '../../assets/flecha-izq.svg';
import loanRequestService from '../../services/loanRequest.service';
import offerService from '../../services/offer.service';
import '../../styles/components/offerSelector.scss';
import Loader from '../loader/Loader';
import { numberToCurrencyText } from '../utils/numberUtils';
import arrowLeft from './../../assets/flecha-der.svg';
import Toaster from './../../components/alerts/Toaster';
import eventTrackingService from './../../services/eventtracking.service';
import AmountSelectionSlider from './AmountSelectionSlider';

const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
};

const settingsMobile = {
    dots: false,
    infinite: false,
    //slidesToShow: 2,
    //slidesToScroll: 2,
    rows: 6,
    slidesPerRow: 2,
};

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: null,
        height: null,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
}

function PrevArrow(props) {
    const size = useWindowSize();
    const width = size.width;
    const topValue = width > 800 ? '70%' : '50%';
    const { onClick } = props;
    return <img src={arrowRight} className="arrowRight flex center" onClick={onClick} style={{ top: topValue, zIndex: '1' }} alt="" />;
}

function NextArrow(props) {
    const size = useWindowSize();
    const width = size.width;
    const topValue = width > 800 ? '70%' : '50%';

    const { onClick } = props;
    return (
        <div className="arrowLeft flex center" style={{ top: topValue, left: '100%' }}>
            <img src={arrowLeft} onClick={onClick} alt="" />
        </div>
    );
}

export const OfferSelector = (props) => {
    const {
        loanRequest,
        minimumAmount,
        maximumAmount,
        initialAmount,
        isCustomer,
        simulationInstallments,
        simulationSelectedInstallment,
        step,
        showSlider,
        analyticsStep,
        hasAccount,
    } = props;
    const [currentProductValue, setCurrentProductValue] = useState(initialAmount);
    const [installments, setInstallments] = useState(null);
    const [installmentSelected, setInstallmentSelected] = useState(null);
    const [loading, setLoading] = useState(true);
    const [message] = useState(null);
    const [open, setOpen] = useState(false);

    const size = useWindowSize();
    const width = size.width;
    const breakpoint = 600;

    const sliderSettings = width < breakpoint ? { ...settingsMobile } : { ...settings };
    const sliderClass = width < breakpoint ? 'SliderMobile' : 'Slider';
    const sizeOptions = width < breakpoint ? '83vw' : '99%';

    useEffect(() => {
        setCurrentProductValue(initialAmount);
    }, [initialAmount]);

    useEffect(() => {
        setInstallments(simulationInstallments);
    }, [simulationInstallments]);

    useEffect(() => {
        setInstallmentSelected(simulationSelectedInstallment);
    }, [simulationSelectedInstallment]);

    useEffect(() => {
        async function getInstallmentPlans() {
            try {
                setLoading(true);
                let newInstallments = null;
                if (loanRequest) {
                    newInstallments = (await offerService.getInstallments(loanRequest.hashKey, currentProductValue)).data;
                    var selectedProductOptions = {
                        selectedProductId: 'CASH_AMOUNT',
                        installmentSelected: {
                            requestedAmount: currentProductValue,
                            disbursementAmount: newInstallments[0]?.disbursementAmount,
                            availableProducts: [],
                        },
                    };
                    await loanRequestService.setSelectedProducts(loanRequest.hashKey, selectedProductOptions);
                } else {
                    newInstallments = (await offerService.getSimulationInstallments(currentProductValue, isCustomer)).data;
                }
                setInstallments(newInstallments);
                setInstallmentSelected(newInstallments[0]);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                if (error.response) {
                    const { cause, errorCode } = error.response.data;
                    if (errorCode === 'NO_INSTALLMENT_OPTIONS') {
                        setInstallments(null);
                        setInstallmentSelected(null);
                    } else {
                        console.error(cause);
                    }
                } else {
                    console.error(error);
                }
            }
        }
        if (currentProductValue && currentProductValue >= minimumAmount && currentProductValue <= maximumAmount) {
            getInstallmentPlans();
        }
    }, [currentProductValue]);

    useEffect(() => {
        props.onSelect(currentProductValue, installmentSelected);
    }, [installmentSelected]);

    const handleCurrentProductValueChanged = (amount) => {
        setCurrentProductValue(amount);
    };

    const installmentSelectedClicked = (installment) => {
        eventTrackingService.trackEvent('simulation-changed', 'seleccion de cuotas', installment.installments, analyticsStep);
        setInstallmentSelected(installment);
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const specialMargin = matches ? '' : 'mt-1';

    return (
        <>
            <div className="mt-3"></div>

            {currentProductValue && (
                <AmountSelectionSlider
                    value={currentProductValue}
                    minAmount={minimumAmount}
                    maxAmount={maximumAmount}
                    step={step}
                    onAmountChange={handleCurrentProductValueChanged}
                    showSlider={showSlider}
                    extraInfo="Ingresá el monto en miles. Caso contrario se redondeará automáticamente."
                />
            )}
            {installments?.[0]?.disbursementTimeTotalCharges > 0 && (
                <div className="importantDiv flex center mt-2">
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item container lg={12} sm={12} md={12} xl={12} xs={12}>
                            <Grid item container lg={1} sm={3} md={3} xl={1} xs={3} justifyContent="center">
                                <img src={logoexl} alt="" width="40" height="auto" />
                            </Grid>
                            <Grid item container lg={11} sm={9} md={9} xl={11} xs={9} style={{ padding: '5px 15px 5px 0' }}>
                                <p className="fw-400 fs-14 lh-20 color-dark-gray">
                                    {hasAccount && 'La provincia de radicación de tu cuenta tiene impuestos. El monto a acreditar será de '}
                                    {!hasAccount && 'La provincia seleccionada tiene impuestos. El monto a acreditar será de '}
                                    {numberToCurrencyText(installments[0]?.disbursementAmount)}.
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            )}
            <Box className="mt-2"></Box>
            <Grid item container xs={12} direction="row" className="mt-2 verticalCenterWeb">
                <Box className="rounderedStepper">
                    <p className="p">3</p>
                </Box>

                <Grid item xs={11}>
                    <p className="fs-20 fw-400 color-primary ml-1">¿En cuántas cuotas querés pagarlo?</p>
                </Grid>
            </Grid>
            <Loader loading={loading} />
            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />

            <Box className={sliderClass} style={{ width: sizeOptions }}>
                <Slider {...sliderSettings}>
                    {installments &&
                        installments.map((installment, index) => (
                            <div className="boxSlider " key={index} onClick={(event) => installmentSelectedClicked(installment)}>
                                <div className={`boxSliderEffects ${installment === installmentSelected ? 'selected' : ''}`}>
                                    <Grid container direction="row" justifyContent="center" alignItems="center">
                                        <Grid item container direction="row" xs={12} className="mt-2 " justifyContent="center" alignItems="center">
                                            <div className={`fs-22 fw-700 color-primary ${specialMargin}`}>{installment.installments} </div>
                                            <div className={`fs-20 fw-400 color-primary ml-05 ${specialMargin}`}> cuotas de </div>
                                        </Grid>

                                        <Grid item container direction="row" justifyContent="center" alignItems="center">
                                            <div className="fs-22 fw-700 color-primary ml-1 ">
                                                <div className="fw-700"> {numberToCurrencyText(installment.averageFee, 0)}*</div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        ))}
                </Slider>
                {!installments && !loading && (
                    <div className="sliderClass mt-4 error-message">
                        <p className="fw-500 color-red lh-160">No podemos ofrecerte cuotas para ese monto. Seleccioná un monto menor.</p>
                    </div>
                )}
            </Box>
        </>
    );
};

import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Grid, Box, useMediaQuery, makeStyles, useTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MacroStepPage from './shared/MacroStepPage';
import useLoanRequest from '../hooks/useLoanRequest';
import Disclosure from '../components/disclosure/Disclosure';
import FinancialOptionsSummary from '../components/disclosure/FinancialOptionsSummary';
import useCurrentTask from '../components/bpm/useCurrentTask';
import Disclaimer from '../components/disclosure/Disclaimer';
import loanRequestService from '../services/loanRequest.service';
import Toaster from '../components/alerts/Toaster';
import Loader from '../components/loader/Loader';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import '../styles/components/disclosurePage.scss';
import verificationCompleteImage from '../assets/email_verified.svg';
import { SAME_DEVICE_ONBOARDING_MINIMUM_DAYS, getEnv } from '../env';
import { useTranslation } from 'react-i18next';
import { ERROR, LOCAL_STORAGE, ROUTE } from '../constants/global';

const useStyles = makeStyles({
    dialog: {
        position: 'absolute',
        top: 0,
    },
});

const DisclosurePage = (props) => {
    const { hashKey } = props.match.params;
    const theme = useTheme();
    const matchesSmSize = useMediaQuery(theme.breakpoints.down('sm'));
    const { loanRequest, loadingLoanRequest } = useLoanRequest(hashKey);
    const [disclosureSigned, setDisclosureSigned] = useState(false);
    const [loading, setLoading] = useState(null);
    const [message, setMessage] = useState(null);
    const [open, setOpen] = useState(false);
    const task = useCurrentTask(hashKey, props.taskDefinitionKey);
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState(false);
    const { state } = useLocation();
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        let openTimeoutId = null;
        let closeTimeoutId = null;
        if (state && state.emailVerified) {
            openTimeoutId = setTimeout(() => {
                setDialogOpen(true);
            }, 1000);
            closeTimeoutId = setTimeout(() => {
                setDialogOpen(false);
            }, 6000);
        }
        return () => {
            if (openTimeoutId) {
                clearTimeout(openTimeoutId);
            }
            if (closeTimeoutId) {
                clearTimeout(closeTimeoutId);
            }
        };
    }, []);

    useEffect(() => {
        if (loanRequest) {
            setDisclosureSigned(loanRequest.hasSigned);
        }
    }, [loanRequest]);

    const validateSameDeviceMinimumDays = () => {
        const onboardingCompletedTaxId = localStorage.getItem(LOCAL_STORAGE.ONBOARDING_COMPLETED_TAX_ID);
        if (!onboardingCompletedTaxId) {
            return;
        }
        if (onboardingCompletedTaxId === loanRequest.customerTaxId) {
            return;
        }
        const sameDeviceOnboardingMinimumDays = getEnv(SAME_DEVICE_ONBOARDING_MINIMUM_DAYS);
        if (!sameDeviceOnboardingMinimumDays) {
            return;
        }
        const onboardingCompletedDate = localStorage.getItem(LOCAL_STORAGE.ONBOARDING_COMPLETED_DATE);
        if (!onboardingCompletedDate) {
            return;
        }
        const differenceInMilis = new Date().getTime() - Number(onboardingCompletedDate);
        const differenceInDays = differenceInMilis / (1000 * 60 * 60 * 24);
        if (differenceInDays < sameDeviceOnboardingMinimumDays) {
            throw new Error(ERROR.DEVICE_VALIDATION);
        }
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleDisclosureSigned = async () => {
        setDisclosureSigned(true);
    };

    const handleDisclosureUnsigned = async () => {
        setDisclosureSigned(false);
    };

    const completeSignature = async () => {
        setLoading(true);
        try {
            await loanRequestService.finishLoanRequestWithoutSign(hashKey);
            await task.completeTask();
            localStorage.setItem(LOCAL_STORAGE.ONBOARDING_COMPLETED_DATE, new Date().getTime());
            localStorage.setItem(LOCAL_STORAGE.ONBOARDING_COMPLETED_TAX_ID, loanRequest.customerTaxId);
        } catch (error) {
            if (error.response) {
                if (error.response.data) {
                    const { errorCode } = error.response.data;
                    setMessage(t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`) || 'Error durante la ejecución');
                    setOpen(true);
                } else {
                    console.error('Error: ' + error);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    const handleFinishClicked = (event) => {
        event.preventDefault();
        try {
            validateSameDeviceMinimumDays();
        } catch (e) {
            if (ERROR.DEVICE_VALIDATION === e.message) {
                history.push({ pathname: `${ROUTE.REJECTED}/${ERROR.DEVICE_VALIDATION}`, search: history.location.search });
                return;
            }
        }
        completeSignature();
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <MacroStepPage
            headerLead={'¡Último paso!'}
            headerTitle={'Aceptá el contrato'}
            task={task}
            taskDefinitionKey={props.taskDefinitionKey}
            backToText="Carga de datos personales"
            hideStepper={true}
            stepNumber={4}
            totalSteps={4}>
            {!isMobile ? (
                <ContinueMobile hashKey={hashKey} />
            ) : (
                <div className="width-100-per">
                    {loanRequest && (
                        <>
                            <Loader loading={loading || loadingLoanRequest} />
                            <Dialog
                                classes={{
                                    paper: classes.dialog,
                                }}
                                keepMounted
                                open={dialogOpen}
                                onClose={handleDialogClose}
                                hideBackdrop={true}>
                                <DialogContent>
                                    <Grid container justifyContent="center" alignItems="center">
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                <img className="mt-1" src={verificationCompleteImage} width="50px" alt="Email verificado"></img>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p className="fw-500 fs-18 color-primary text-align-center mt-1 mb-2">Hemos verificado tu email</p>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                            </Dialog>
                            <div className="mt-3"></div>
                            <FinancialOptionsSummary loanRequest={loanRequest} />
                            {!matchesSmSize && (
                                <div className="mb-130-res">
                                    <Disclaimer showDivider={true} />
                                </div>
                            )}
                            <Disclosure loanRequest={loanRequest} onSign={handleDisclosureSigned} onUnsign={handleDisclosureUnsigned} />
                            {matchesSmSize && (
                                <div className="mb-130-res">
                                    <Disclaimer showDivider={false} />{' '}
                                </div>
                            )}
                        </>
                    )}
                    <Grid container justifyContent="center" alignItems="center" className=" mt-2">
                        <Grid item xs={12} sm={8} md={6} lg={4} className="flex center">
                            <Box className="mt-4"></Box>
                            <div className="startButton center mb-20-web mt-20-web">
                                <Button
                                    className="width-100-per width-80-res"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleFinishClicked}
                                    disabled={!disclosureSigned}>
                                    Finalizar
                                </Button>
                            </div>
                        </Grid>

                        <Grid item>
                            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                        </Grid>
                    </Grid>
                </div>
            )}
        </MacroStepPage>
    );
};

export default DisclosurePage;

import { Button, Grid } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Toaster from '../components/alerts/Toaster';
import useCurrentTask from '../components/bpm/useCurrentTask';
import ContinueMobile from '../components/deviceCommunication/continueMobile';
import Loader from '../components/loader/Loader';
import CustomerDataUserInputs from '../components/userInputs/CustomerDataUserInputs';
import eventTrackingService from '../services/eventtracking.service';
import loanRequestService from '../services/loanRequest.service';
import './../styles/components/customerDataInput.scss';
import MacroStepPage from './shared/MacroStepPage';

export const PersonalAddressDataInput = (props) => {
    const { hashKey } = props.match.params;
    const { taskDefinitionKey } = props;
    const [loanRequest, setLoanRequest] = useState(null);
    const [validForm, setValidForm] = useState(false);
    const [userInputs, setUserInputs] = useState({});
    const [open, setOpen] = useState(false);
    const [hasAccount, setHasAccount] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(null);
    const task = useCurrentTask(hashKey, taskDefinitionKey);
    const { t } = useTranslation();
    const formRef = useRef();

    useEffect(() => {
        async function getLoanRequest() {
            try {
                setLoanRequest((await loanRequestService.getLoanRequest(hashKey)).data);

                loanRequestService
                    .hasAnyAccount(hashKey)
                    .then((response) => {
                        setHasAccount(response.data);
                    })
                    .catch((error) => {
                        // do nothing with error
                    });
            } catch (error) {
                // do nothing with error
            }
        }

        getLoanRequest();
    }, [hashKey]);

    useEffect(() => {
        setValidForm(true);
    }, [userInputs]);

    useEffect(() => {
        eventTrackingService.trackContentViewEvent('/prestamos-personales/ingreso-datos-contacto');
    }, []);

    const completeTask = async (userInputs) => {
        try {
            setLoading(true);
            await loanRequestService.setCustomerPersonalAddressData(hashKey, userInputs);
            await task.completeTask();
        } catch (error) {
            let errorMessage = t('WORKFLOW_ERROR.UNKNOWN_ERROR.DESCRIPTION');
            if (error.response && error.response.data) {
                const { properties, errorCode } = error.response.data;
                if (errorCode) {
                    errorMessage = t(`WORKFLOW_ERROR.${errorCode}.DESCRIPTION`);

                    if (errorCode === 'ALL_FIELDS_REQUIERED_MUST_BE_COMPLETED' && properties.fieldsToCompleted) {
                        const { fieldsToCompleted } = properties;
                        fieldsToCompleted.map((field) => (errorMessage += ' ' + t(`FIELDS.${field}`)));
                    }
                }
            }
            setMessage(errorMessage);
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleNextPressed = async (event) => {
        setLoading(true);
        event.preventDefault();
        try {
            await formRef.current.handleFormSubmission();
        } catch (error) {
            // do nothing with the error
        } finally {
            setLoading(false);
        }
    };

    const handleComplete = (userInputFilled) => {
        setUserInputs(userInputFilled);
    };

    const ShowToaster = (props) => {
        return <Toaster elevation={6} variant="filled" {...props} />;
    };

    const handleToasterClose = () => {
        setOpen(false);
    };

    const handleOnAnalyticsEvent = (inputName, eventType, value) => {
        if (inputName === 'PtllaLocalidad' && eventType === 'EMPTY_OPTIONS_LIST') {
            eventTrackingService.trackEvent('localidad_erronea', value, null);
        }
    };

    const handleFormError = (error) => {
        setMessage(error.message);
        setOpen(true);
    };

    const calculateSameLineIndexes = (testingGroup) => {
        return testingGroup==='A'?[2, 3]:[2, 3, 5, 6];
    }

    const getStepName = (testingGroup) => {
        return testingGroup ==='A'?taskDefinitionKey:"personalAddressDataInputGroupB";
    }

    return (
        <>
            <MacroStepPage
                headerLead="Ya elegiste tu préstamo y cuotas"
                headerTitle="Completá tus datos"
                hashKey={hashKey}
                task={task}
                taskDefinitionKey={taskDefinitionKey}
                backToText={isMobile ? 'Selección de Oferta' : undefined}
                hideStepper={true}
                stepNumber={3}
                totalSteps={4}>
                {!isMobile ? (
                    <ContinueMobile hashKey={hashKey} />
                ) : (
                    <>
                        <Loader loading={loading} />
                        <form onSubmit={handleNextPressed}>
                            <Grid item container className="mb-180-res">
                                {loanRequest && (
                                    <CustomerDataUserInputs
                                        ref={formRef}
                                        sameLineIndexes={calculateSameLineIndexes(loanRequest.testingGroup)}
                                        stepName={getStepName(loanRequest.testingGroup)}
                                        hashKey={loanRequest.hashKey}
                                        onComplete={handleComplete}
                                        onFormSubmitted={completeTask}
                                        onError={handleFormError}
                                        onAnalyticsEvent={handleOnAnalyticsEvent}
                                    />
                                )}
                            </Grid>

                            <Grid item container direction="row" justifyContent="center" alignItems="center" xs={12}>
                                <Grid item xs={12} sm={5} md={6} lg={4}>
                                    <div className="mb-footer startButton center mb-20-web mt-20-web">
                                        <Button
                                            className="width-100-per width-80-res"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={!validForm || loading}>
                                            Siguiente
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                            <ShowToaster open={open} textToShow={message} type="error" handleToasterClose={handleToasterClose} />
                        </form>
                    </>
                )}
            </MacroStepPage>
        </>
    );
};

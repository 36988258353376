import { Box, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect } from 'react';
import eventTrackingService from '../../services/eventtracking.service';
import '../../styles/components/financialOptionsSummary.scss';
import { numberToCurrencyText, numberToPercentageText } from './../utils/numberUtils';

const FinancialOptionsSummary = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSmSize = useMediaQuery(theme.breakpoints.down('sm'));
    const { loanRequest } = props;

    useEffect(() => {
        eventTrackingService.trackContentViewEvent('/prestamos-personales/datos-confirmacion');
    }, []);

    return (
        <Grid container direction="row">
            <Grid item xs={12}>
                <h5 className={matchesSmSize ? classes.titleTextMobile : classes.titleText}>
                    Solicitaste <span className="titleHightlightText"> {numberToCurrencyText(loanRequest.requestedAmount)}</span> en{' '}
                    {loanRequest.installmentsNumberSelected} cuotas promedio de {numberToCurrencyText(loanRequest.installmentAmountSelected)}*
                </h5>
                <Box m={1} />
                {/* <a className="link">Modificar monto o cuotas</a> */}
            </Grid>

            <Grid
                item
                container
                xs={12}
                sm={6}
                style={{ marginTop: '20px' }}
                className={['mt-2, fw-400', matchesSmSize ? 'tableTextMobile' : 'tableText']}>
                <Grid container className="fw-700 fs-16 color-primary">
                    <Grid item xs={9}>
                        Total del préstamo
                    </Grid>
                    <Grid item xs={3} className="text-align-right">
                        {numberToCurrencyText(loanRequest.requestedAmount)}
                    </Grid>
                </Grid>
                <Grid container className="fw-700 fs-16 color-primary">
                    <Grid item xs={9}>
                        Monto a acreditar
                    </Grid>
                    <Grid item xs={3} className="text-align-right">
                        {numberToCurrencyText(loanRequest.disbursementAmount)}
                    </Grid>
                </Grid>
                {loanRequest?.totalDisbursementChargesAmount > 0 && (
                    <Grid container>
                        <Grid item xs={9}>
                            Impuesto al débito/crédito (15%)**
                        </Grid>
                        <Grid item xs={3} className="text-align-right">
                            {numberToCurrencyText(loanRequest.totalDisbursementChargesAmount)}
                        </Grid>
                    </Grid>
                )}
                <Grid container>
                    <Grid item xs={12}>
                        <div className="divider color-gray mt-1 mb-1"></div>
                    </Grid>
                    <Grid item xs={9}>
                        Sistema de amortización
                    </Grid>
                    <Grid item xs={3} className="text-align-right">
                        Francés
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={9}>
                        Tipo de tasa
                    </Grid>
                    <Grid item xs={3} className="text-align-right">
                        Fija
                    </Grid>
                </Grid>

                <Grid container className="mt-2">
                    <Grid container item xs={7}>
                        <Grid item xs={6}>
                            <p className="fs-14 fw-400 break-word">TNA: {numberToPercentageText(loanRequest.tna)}</p>
                        </Grid>
                        <Grid item xs={6}>
                            <p className="fs-14 fw-400 break-word text-align-center">TEA: {numberToPercentageText(loanRequest.tea)}</p>
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <p className="fs-16 fw-700 break-word text-align-right">CFTEA: {numberToPercentageText(loanRequest.cft)}</p>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(() => ({
    titleText: {
        fontFamily: 'Roboto',
        fontSize: '28px',
        fontWeight: '500',
        lineHeight: '40px',
        color: '#082f57',
    },
    titleTextMobile: {
        fontFamily: 'Roboto',
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '26px',
        color: '#082f57',
    },
    ratesText: {
        fontSize: '14px',
        lineHeight: '24px',
        color: '#515151',
    },
    cftText: {
        fontSize: '16px',
        lineHeight: '24px',
        color: '#515151',
        fontWeight: 'bold',
    },
}));

export default FinancialOptionsSummary;

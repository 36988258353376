import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

const LinkUserInput = (props) => {   
    const { conditioningValue,userInput } = props;
    const [url,setUrl] = useState(userInput.userInputOptions.url || "#");
    const [showData, setShowData] = useState(typeof conditioningValue === 'string' && conditioningValue.trim() !== '' ? true : false);
   
    useEffect(() => {
        if (conditioningValue) {
            setShowData(true);
        }
    }, [conditioningValue]);

    return (
        <Grid item container xs={12} direction="column" justifyContent="flex-end" alignItems="center">
            {showData && <Link 
                to={{ pathname: url }} 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ height: '100%' ,textDecoration: 'none', color: 'blue' }}
                title={userInput.userInputOptions.hint}
            >
                {userInput.userInputOptions.label}
            </Link>}        
        </Grid>
    );

}

export default LinkUserInput;
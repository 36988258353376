import { useState, useEffect } from 'react';
import loanRequestService from '../services/loanRequest.service';

const useLoanRequest = (hash) => {
    const [loanRequest, setLoanRequest] = useState(null);
    const [error, setError] = useState(null);
    const [loadingLoanRequest, setLoadingLoanRequest] = useState(false);

    const updateLoanRequest = async (hash) => {
        try {
            setLoadingLoanRequest(true);
            setLoanRequest((await loanRequestService.getLoanRequest(hash)).data);
        } catch (error) {
            setError(error);
        } finally {
            setLoadingLoanRequest(false);
        }
    };

    useEffect(() => {
        if (hash) {
            updateLoanRequest(hash);
        }
    }, [hash]);

    return { loanRequest, error, loadingLoanRequest };
};

export default useLoanRequest;
